import React from 'react'

import { HStack, Icon } from '@chakra-ui/react'
import Link from '@components/ui/Link'
import { IconType } from 'react-icons/lib'

interface IconLinkProps {
    text: string
    to: string
    icon?: IconType
    iconPosition?: 'left' | 'right'
    color?: string
    hoverColor?: string
    pt?: string | string[]
}

const IconLink = ({
    text,
    to,
    icon,
    iconPosition = 'right',
    color = 'indigo.500',
    hoverColor = 'pink.500',
    ...rest
}: IconLinkProps) => (
    <HStack {...rest} color={color} _hover={{ color: hoverColor }}>
        {icon && iconPosition === 'left' && <Icon as={icon} color="inherit" />}

        <Link
            to={to}
            fontSize="sm"
            fontWeight="bold"
            color="inherit"
            style={{ textDecoration: 'underline', textAlign: 'center' }}
        >
            {text}
        </Link>

        {icon && iconPosition === 'right' && <Icon as={icon} color="inherit" />}
    </HStack>
)

export default IconLink
